import React from "react"
import tw from "twin.macro"

import { Heading, SubHeading, SubSubHeading } from "./primitives/panelHeading"
import Summary from "./summary"

import { useAllContentQuery } from "../queries/useAllContentQuery"

const Container = tw.div`
mb-12 max-w-screen-xl mx-auto md:mb-24
`
const List = tw.ul`
flex flex-col mt-6 md:mt-12 px-6 md:grid md:col-gap-8 md:row-gap-10 lg:grid-cols-3
`

const FlatPanel = ({
  query,
  columns,
  heading,
  subHeading,
  subSubHeading,
  image,
}) => {
  const data = useAllContentQuery(query)
  return (
    <Container>
      {heading ? <Heading>{heading}</Heading> : null}
      {subHeading ? <SubHeading>{subHeading}</SubHeading> : null}
      {subSubHeading ? <SubSubHeading>{subSubHeading}</SubSubHeading> : null}
      <List>
        {data.map(item => {
          return (
            <Summary
              key={item.id}
              image={item.image}
              heading={item.heading}
              subHeading={item.subHeading}
              ctaText={item.ctaText}
              ctaLink={item.ctaLink}
            />
          )
        })}
      </List>
    </Container>
  )
}

export default FlatPanel
