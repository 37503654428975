import React from "react"
import tw from "twin.macro"
import SignupForm from "./signupForm"
import { Heading, SubHeading } from "./primitives/panelHeading"

const SignupPanelContainer = tw.div`
mx-auto max-w-screen-md flex flex-col
`
const SignUpTextContainer = tw.div`flex-col py-5`
const SignUpFormContainer = tw.div`mb-12 lg:mb-24`

export const SignupPanel = ({ heading, subHeading }) => {
  return (
    <SignupPanelContainer>
      <SignUpTextContainer>
        <Heading>{heading}</Heading>
        <SubHeading>{subHeading}</SubHeading>
      </SignUpTextContainer>
      <SignUpFormContainer>
        <SignupForm
          placeholder="Enter your email and..."
          buttonText="Join the Crowd!"
        ></SignupForm>
      </SignUpFormContainer>
    </SignupPanelContainer>
  )
}
