import React from "react"
import tw from "twin.macro"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import Waves from "./waves"

const HeroContainer = tw.div`
bg-gradient-l-primary pt-6 mb-12 md:mb-24
`
const HeroContentContainer = tw.div`
container pb-6 px-3 mx-auto flex flex-wrap flex-col sm:p-2 md:pt-6 lg:flex-row items-center justify-between
`
const LeftColoumn = tw.div`
flex flex-col w-3/4 lg:w-1/2 justify-center items-center text-center lg:items-start lg:text-left sm:mx-auto
`
const RightColumn = tw.div`
hidden md:w-full md:block md:w-3/4 md:pt-6 lg:pt-12 lg:pr-12 lg:w-2/5
`
const HeroTitle = tw.h1`
font-bold leading-tight text-gray-800 text-3xl md:text-4xl mb-1 md:mb-3
`
const HeroSubTitle = tw.p`
leading-normal mb-6 text-gray-900 text-lg 
`

const Button = tw.div`
justify-end text-center px-2 bg-secondary w-24 sm:w-32 text-gray-200 py-1 rounded shadow text-xs sm:text-base font-bold hover:bg-primary focus:outline-none hover:shadow-xl
`

const Hero = ({
  heading,
  subHeading,
  cta,
  waves,
  ctaUrl,
  ctaButtonText,
  ...props
}) => {
  const data = useStaticQuery(graphql`
    query HeroImage {
      image: file(relativePath: { eq: "hero-team.png" }) {
        id
        childImageSharp {
          fixed(width: 854) {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <HeroContainer>
      <HeroContentContainer>
        <LeftColoumn>
          <HeroTitle>{heading}</HeroTitle>
          <HeroSubTitle>{subHeading}</HeroSubTitle>
          <Button>
            <a href={ctaUrl}>{ctaButtonText}</a>
          </Button>
        </LeftColoumn>
        <RightColumn>
          <Img
            fluid={data.image.childImageSharp.fluid}
            alt="An illustration of a team working together"
          />
        </RightColumn>
      </HeroContentContainer>
      {waves ? <Waves /> : null}
    </HeroContainer>
  )
}

export default Hero
