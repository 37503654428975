import React from "react"
import tw from "twin.macro"

const Container = tw.div`
flex flex-col items-center mb-12 md:mb-0
`
const Image = tw.img`
text-center h-40 w-40 mb-6 object-cover
`
const TextContainer = tw.div`
prose-sm md:prose items-start text-center
`

const Heading = tw.h3`
text-gray-800
`
const SubHeading = tw.p`
text-gray-700
`
const Item = tw.li`
list-none
`

const ItemSummary = ({ ...props }) => {
  return (
    <Item key={props.key}>
      <Container>
        <Image src={props.image + "?width=300"} />
        <TextContainer>
          <Heading>{props.heading}</Heading>
          <SubHeading>{props.subHeading}</SubHeading>
        </TextContainer>
      </Container>
    </Item>
  )
}

export default ItemSummary
