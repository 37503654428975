import { useStaticQuery, graphql } from "gatsby"

export const useAllTestimonialsQuery = () => {
  const { webinyHeadlessCms } = useStaticQuery(
    graphql`
      query allTestimonials {
        webinyHeadlessCms {
          listTestimonials {
            data {
              id
              image
              heading
              testimonial
              clientName
              clientRole
              updatedOn
            }
          }
        }
      }
    `
  )
  return webinyHeadlessCms.listTestimonials.data
}
