import React from "react"
import tw from "twin.macro"
import Testimonial from "./testimonial"
import { Heading, SubHeading } from "./primitives/panelHeading"

import { useAllTestimonialsQuery } from "../queries/useAllTestimonialsQuery"

const Container = tw.div`
mb-12 max-w-screen-xl mx-auto md:mb-16
`
const List = tw.li`
flex flex-row flex-wrap justify-center
`

const Testimonials = ({ heading, subHeading, subSubHeading, ...props }) => {
  const testimonials = useAllTestimonialsQuery()
  return (
    <Container>
      <Heading>{heading}</Heading>
      <SubHeading>{subHeading}</SubHeading>
      <List>
        {testimonials.map(testimonial => {
          return (
            <Testimonial
              key={testimonial.id}
              image={testimonial.image}
              testimonial={testimonial.testimonial}
              clientName={testimonial.clientName}
              clientRole={testimonial.clientRole}
            />
          )
        })}
      </List>
    </Container>
  )
}

export default Testimonials
