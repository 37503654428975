import React from "react"
import tw from "twin.macro"

const Container = tw.div`
max-w-xs py-4 px-8 bg-white shadow-lg rounded-lg m-5 mt-20 bg-gray-100
`

const ImageContainer = tw.div`
flex justify-center md:justify-end -mt-16
`

const Image = tw.img`
w-20 h-20 object-cover rounded-full border-2 border-primary 
`

const CopyContainer = tw.div`
min-h-full flex flex-col justify-between
`

const TestimonialHeading = tw.h2`
text-secondary text-lg font-semibold
`

const TestimonialCopy = tw.p`
text-sm leading-tight text-left text-gray-800 pt-6
`

const ClientContainer = tw.div`
flex-col mt-2 text-right mb-2
`

const ClientName = tw.h3`
text-base font-medium text-secondary
`
const ClientRole = tw.p`
text-gray-500 text-sm leading-tight
`

const Testimonial = ({ image, ...props }) => {
  return (
    <Container>
      <ImageContainer>
        <Image src={image + "?width=100"} />
      </ImageContainer>
      <CopyContainer>
        <div>
          <TestimonialHeading>{props.heading}</TestimonialHeading>
          <TestimonialCopy>{props.testimonial}</TestimonialCopy>
        </div>
        <ClientContainer>
          <ClientName>{props.clientName}</ClientName>
          <ClientRole>{props.clientRole}</ClientRole>
        </ClientContainer>
      </CopyContainer>
    </Container>
  )
}

export default Testimonial
