import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import tw from "twin.macro"
import SEO from "../layout/seo"
import Hero from "../components/columnHero"
import FlatPanel from "../components/flatPanel"
import TextPanel from "../components/textPanel"
import { SignupPanel } from "../components/signupPanel"
import Testimonials from "../components/testimonialPanel"
import Layout from "../layout"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query HomeHeading {
      webinyHeadlessCms {
        getPageHeadings(where: { page: "Home" }) {
          data {
            id
            heading
            subHeading
          }
        }
      }
    }
  `)

  const header = data.webinyHeadlessCms.getPageHeadings.data

  return (
    <>
      <SEO
        title="The Innovate Crowd"
        description="Helping teams perform at their best by cultivating extraordinary leaders"
      />
      <Layout>
        <Hero
          heading={header.heading}
          subHeading={header.subHeading}
          cta={true}
          waves={true}
          ctaButtonText="Take the assessment"
          ctaUrl="https://il-learning.theinnovatecrowd.com"
        />
        <TextPanel
          heading="Book your free discovery call."
          subHeading="We will help you clarify some of your biggest challenges and explore ways of overcoming them."
          // subSubHeading="Although of course if you do work with us afterwards to help solve the problem, we'd be delighted!"
          ctaText="Book your FREE session"
          ctaLink="https://keap.app/booking/theinnovatecrowd/free-45mins-consultancy"
        />
        <FlatPanel
          query="Products"
          heading="Or if you have a specific objective, this is what we do."
        />
        <FlatPanel
          query="Features"
          heading="The frameworks we use have been carefully selected"
        />
        <Testimonials heading="We love our clients and they love us" />
        <SignupPanel heading={header.heading} subHeading={header.subHeading} />
      </Layout>
    </>
  )
}

export default IndexPage
